import { useRouter } from 'next/router';
import { Fragment, useMemo } from 'react';
import FilterableLayout from 'src/components/containers/FilterableLayout/FilterableLayout';
import FilterableLayoutHeaderSlot from 'src/components/containers/FilterableLayout/FilterableLayoutHeaderSlot';
import OpenGraphCategory from 'src/components/containers/OpenGraph/OpenGraphCategory';
import { CategoryProducts } from 'src/redux/apiTypes';
import { selectStoreId } from 'src/redux/cart/general/cartSelectors';
import { selectFiltersLikeCategories } from 'src/redux/categories/categoriesSelectors';
import { useAppSelector } from 'src/redux/hooks';
import useChainService from 'src/services/chain/useChainService';
import useLocalization from 'src/services/localization/useLocalization';
import useBanners from 'src/swr/useBanners/useBanners';
import personalizeString from 'src/utils/cart/personalizeString';
import useCheckExcisableAvailability from '../../hooks/useCheckExcisableAvailability';
import useCustomCategoryPageLoadMore from '../../hooks/useCustomCategoryPageLoadMore';
import CustomCategoryPageHead from '../CustomCategoryPageHead/CustomCategoryPageHead';
import PromotionsPageHead from '../PromotionsPageHead/PromotionsPageHead';

export interface Query {
  custom_category_id: string;
  'category-id'?: string;
  page: string;
}

export interface CustomCategoryPageProps {
  categoryData: CategoryProducts;
}

export default function CustomCategoryPage(props: CustomCategoryPageProps) {
  const {
    categoryData,
  } = props;

  const localize = useLocalization();
  const { query } = useRouter<Query>();
  const categoryId = query.custom_category_id;
  const filterCategoryId = query['category-id'];
  const categoryIdForCheckAlcohol = filterCategoryId || categoryId;

  const filtersLikeCategoriesList = useAppSelector(selectFiltersLikeCategories);
  const { language, baseURL } = useAppSelector((s) => s.app);
  const chain = useChainService();
  const storeId = useAppSelector(selectStoreId);

  const place = categoryId;
  const selectedCategory = useMemo(
    () => filtersLikeCategoriesList.find(i => i.id === categoryId),
    [categoryId, filtersLikeCategoriesList],
  );

  const { count, filters, results, count_available } = categoryData;
  const title = selectedCategory?.title;
  const {
    productsToShow,
    isFetching,
    handleLoadMore,
  } = useCustomCategoryPageLoadMore(results, categoryId);

  const metaTitle = personalizeString(localize('meta.title.custom_category'), [title, chain.name]);
  const description = personalizeString(localize('meta.description.custom_category'), [title, chain.name]);

  const banners = useBanners({
    place: 'category_page',
    storeId,
    categoryId,
    canFetch: true,
  });

  useCheckExcisableAvailability({
    dataAreReady: !!categoryData,
    categoryId: categoryIdForCheckAlcohol,
    categoryProducts: results,
  });

  return (
    <Fragment>
      {
        categoryId === 'promotions'
          ? <PromotionsPageHead title={metaTitle} description={description} />
          : <CustomCategoryPageHead title={metaTitle} description={description} />
      }
      <FilterableLayout
        products={productsToShow}
        currentLocale={language}
        filters={filters}
        count={count}
        countAvailable={count_available}
        isFetching={isFetching}
        productPlace={place}
        sliderSlot={banners}
        headerSlot={
          <FilterableLayoutHeaderSlot
            categoryId={categoryId}
            title={title}
            language={language}
            baseURL={baseURL}
          />
        }
        onLoadMore={handleLoadMore}
      />
      <OpenGraphCategory
        title={metaTitle}
        description={description}
      />
    </Fragment>
  );
}
