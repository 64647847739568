import { useEffect, useMemo, useState } from "react";
import selectSelectedDelivery from "src/redux/cart/general/selectSelectedDelivery";
import { useAppSelector } from "src/redux/hooks";
import useLocalization from 'src/services/localization/useLocalization';
import useChains from "src/swr/useChains";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { requestGetUserRetails } from "../ajax/requestGetUserRetails";
import getRetailInfoLists from "../helpers/getRetailInfoLists";
import getRetailInfoQueryStringForLink from "../helpers/getRetailInfoQueryStringForLink";
import { getRetailsInfoRequestQueryString } from "../helpers/getRetailsInfoRequestQueryString";
import { RetailInfoLists } from "../types";

const useChainsForSidebar = () => {
  const { fetchConfig } = useAppConfig();
  const { chains } = useChains();
  const isLoggedIn = useAppSelector(s => s.user.isLoggedIn);

  const localize = useLocalization();
  const delivery = useAppSelector(selectSelectedDelivery);
  const deliveryType = delivery?.type;
  const store = useAppSelector(s => s.storeManagement.store);

  const [retailInfoLists, setRetailInfoLists] = useState<RetailInfoLists>({ available: [], unavailable: [] });

  const deliveryAsQueryStringForRequest = useMemo(() => {
    return getRetailsInfoRequestQueryString(delivery, store);
  }, [delivery, store]);

  const deliveryAsQueryStringForLink = useMemo(() => {
    return getRetailInfoQueryStringForLink(delivery);
  }, [delivery]);

  useEffect(() => {
    if (chains.length > 0) {
      (async () => {
        const userRetails = await requestGetUserRetails(
          deliveryType,
          deliveryAsQueryStringForRequest,
          fetchConfig,
        );

        const filteredChains = chains.filter(c => c.id !== fetchConfig.xChain);

        const newRetailInfoLists = getRetailInfoLists({
          chains: filteredChains,
          userRetails,
          deliveryType,
          language: fetchConfig.language,
          deliveryAsQueryStringForLink,
          localize,
        });

        setRetailInfoLists(newRetailInfoLists);
      })();
    }
  }, [
    deliveryType,
    deliveryAsQueryStringForRequest,
    deliveryAsQueryStringForLink,
    fetchConfig,
    chains,
    isLoggedIn,
    localize,
  ]);

  const skeletonIsDisplayed = retailInfoLists.available.length === 0 && retailInfoLists.unavailable.length === 0;
  const allVisibleChains = [...retailInfoLists.available, ...retailInfoLists.unavailable];
  const chainsWithCartItemsCount = allVisibleChains.reduce((acc, chain) => (Number(chain.cartCount) > 0 ? ++acc : acc), 0);

  return {
    retailInfoLists,
    skeletonIsDisplayed,
    chainsWithCartItemsCount,
  };
};

export default useChainsForSidebar;
